.k-multiselect.custom::after {
  content: "\25bc";
  display: inline-block;
  position: absolute;
  font-size: 10px;
  color: var(--inchcape-light-grey);
  z-index: 2;
  top: 15%;
  right: 4%;
  cursor: pointer;
}

.k-multiselect.custom .k-i-x {
  display: none;
}

.k-multiselect.custom .k-input-inner {
  cursor: pointer;
}
