.campaign-builder {
  grid-area: 1 / 1 / -1 / -1;
}

.campaign-builder .header img {
  cursor: pointer;
}

.campaign-builder-cont {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.builder-input {
  grid-column: 1/3;
}

.builder-buttons {
  grid-column: 1/3;
  gap: 20px;
}

.percent {
  margin: 0;
  font-family: "Poppins";
  font-weight: 600;
  font-size: 14px;
  color: rgba(0, 35, 58, 0.6);
  margin: 0 6px;
}

.span-to {
  font-family: "Poppins";
  font-weight: 600;
  font-size: 14px;
  color: rgba(0, 35, 58, 0.6);
  margin: 0 22px;
}

.campaign-builder .k-datepicker .k-button-solid-base {
  border-color: var(--inchcape-white);
  background-color: var(--inchcape-white);
  color: rgba(0, 35, 58, 0.6);
}

.campaign-builder .k-button-icon {
  color: rgba(0, 35, 58, 0.6);
}

/* Campaign Builder Input Styling */
.builder-input,
.builder-replace-deal,
.builder-exist-deal {
  background-color: var(--inchcape-white);
  border-radius: 6px;
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.05);
}

.campaign-builder-inputs {
  padding: 20px;
}

.campaign-builder-inputs p,
.replacement-deal p,
.existing-deal p {
  margin: 0;
  font-family: "Poppins";
  font-weight: 600;
  font-size: 14px;
  color: rgba(0, 35, 58, 0.6);
}

.error-input-text {
  font-family: "Poppins";
  /* font-weight: 600; */
  color: red;
  font-size: 12px;
  line-height: 20px;
  display: flex;
}

.error-input {
  color: red;
  border-color: red;
}

.error-input .k-i-calendar {
  color: red;
  border-color: red;
}

.campaign-builder-inputs .k-input-flat.k-invalid {
  border-color: rgba(0, 0, 0, 0.08);
}

/* Campaign Builder Replacement Deal Styling */
.replacement-deal,
.existing-deal {
  padding: 23px 33px 33px;
}

.replacement-deal h2,
.existing-deal h2 {
  margin-bottom: 25px;
}

.replace-deal-cont {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: flex-end;
  row-gap: 54px;
  column-gap: 40px;
  margin-bottom: 25px;
}

.replace-deal-cont div:nth-child(2) {
  grid-column: 1/2;
  grid-row: 2/3;
}

.replace-deal-cont div:nth-child(3) {
  grid-column: 2/3;
  grid-row: 2/3;
  align-self: flex-end;
  /* justify-self: center; */
}

.campaign-builder .primary-btn,
.campaign-builder .secondary-btn {
  padding: 11px 32px;
  text-align: center;
  font-family: "Poppins Bold";
  font-size: 13px;
}

.deposit-cont {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: flex-end;
  row-gap: 25px;
  column-gap: 40px;
}

.deposit-cont .k-input:focus,
.deposit-cont .k-input-md {
  outline: none;
  box-shadow: none;
}

.interest-input-box .k-input {
  cursor: default;
}

/* Campaign Builder Existing Deal Styling */
.existing-deal-cont {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: flex-end;
  row-gap: 25px;
  column-gap: 40px;
}

.existing-deal-cont div:nth-child(2) {
  grid-column: 1/2;
  grid-row: 2/3;
}

.existing-deal-cont div:nth-child(3) {
  grid-column: 2/3;
  grid-row: 2/3;
}

.existing-deal-cont div:nth-child(4) {
  grid-column: 1/3;
  grid-row: 3/4;
}

.existing-deal-cont div:nth-child(5) {
  grid-column: 1/3;
  grid-row: 4/5;
}

.existing-deal-cont div:nth-child(6) {
  grid-column: 1/3;
  grid-row: 5/6;
}

.existing-deal-cont div:nth-child(7) {
  grid-column: 1/3;
  grid-row: 6/7;
}
