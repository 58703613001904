.conversion-report {
  grid-area: 1 / 1 / -1 / -1;
}

.conversion-report .header img {
  cursor: pointer;
}

.conversion-report-header {
  background: #fafafa;
  border: 1px solid rgba(0, 0, 0, 0.08);
  padding: 9px;
  display: flex;
  gap: 10px;
}

.conversion-report-header button {
  display: flex;
  align-items: center;
  border-radius: 3px;
  padding: 5px 12px;
}

.conversion-report-header button img {
  width: 15px;
  height: auto;
}

.conversion-report-header button span {
  font-family: "Poppins";
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
}

.conversion-report-header .btn-idle {
  background-color: var(--inchcape-white);
  border: 1px solid var(--inchcape-blue);
}

.conversion-report-chart {
  height: 600px;
  background-color: var(--inchcape-white);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 105px 50px;
  gap: 20px;
}

.conversion-report-chart .k-chart {
  width: 495px;
  height: 400px;
}

.conversion-stat-holder {
  height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
}

.chart-status {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  padding: 8px 12px;
  border-radius: 2px;
}

.conversion-grid-header {
  display: grid;
  grid-template-columns: repeat(5, minmax(0, 1fr));
  border: 1px solid rgba(0, 0, 0, 0.08);
  gap: 20px;
  padding: 11px 11px 11px 20px;
  background-color: #fff;
}

.conversion-grid-header p {
  margin: 0;
  font-family: "Poppins";
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--inchcape-label);
}

.conversion-grid-header .k-picker-solid:hover {
  background: var(--inchcape-white);
}

.conversion-grid-header .k-multiselect::after {
  content: "\25bc";
  display: inline-block;
  position: absolute;
  font-size: 10px;
  color: var(--inchcape-light-grey);
  z-index: 2;
  top: 23%;
  right: 3%;
  cursor: pointer;
}

.conversion-grid-header .k-multiselect {
  caret-color: transparent;
}

.conversion-grid-header .k-input-md .k-input-values > .k-input-inner {
  cursor: pointer;
}

/* FILTER STYLING */
.conversion-grid-header .k-multiselect .k-input-values {
  flex-wrap: nowrap;
  height: 30px;
}

.conversion-grid-header .k-multiselect .k-input-inner {
  caret-color: transparent;
  width: 100%;
  position: absolute;
  background-color: transparent;
}

.conversion-grid-header .k-clear-value,
.conversion-grid-header .k-chip-actions {
  display: none;
}

.conversion-grid-header .k-chip:hover {
  background: var(--inchcape-white);
}

.conversion-grid-header .k-chip-list {
  width: 93%;
}

.conversion-grid-header .k-chip {
  width: 100%;
}

/* daterange picker */
.conversion-grid-header .daterange-picker {
  grid-column: 1 / 3;
}

.conversion-grid-header .k-floating-label-container > .k-label {
  font-family: "Poppins" !important;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

.conversion-grid-header .k-floating-label-container,
.conversion-grid-header .k-dateinput,
.conversion-grid-header .k-daterangepicker label {
  width: 100%;
}

.conversion-grid-header > label {
  width: 50%;
}

.conversion-grid-header .k-daterangepicker {
  gap: 20px;
}

.conversion-grid .k-grid {
  height: 45vh;
}

/* Grid Styling */
.conversion-grid {
  overflow: hidden;
  overflow-x: auto;
}

@media screen and (max-width: 1024px) {
  .conversion-grid-header {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .conversion-grid-header .daterange-picker {
    grid-column: 2/4;
  }

  .conversion-chart-holder .k-chart {
    width: 390px !important;
  }
}
