.usage-report {
  width: 100%;
  grid-area: 1 / 1 / -1 / -1;
}

/* .usage-report-cont {
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
} */

.usage-report .header img {
  cursor: pointer;
}

.usage-report-header {
  background: #fafafa;
  border: 1px solid rgba(0, 0, 0, 0.08);
  padding: 9px;
  display: flex;
  gap: 10px;
}

.usage-report-header button {
  display: flex;
  align-items: center;
  border-radius: 3px;
  padding: 5px 12px;
}

.usage-report-header button img {
  width: 15px;
  height: auto;
}

.usage-report-header button span {
  font-family: "Poppins";
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
}

.usage-report-header .btn-idle {
  background-color: var(--inchcape-white);
  border: 1px solid var(--inchcape-blue);
}

.usage-chart-box div .k-dropdownlist {
  width: 188px;
}

.usage-chart-holder .k-chart {
  width: 500px !important;

  position: relative;
}

.usage-chart-box .k-chart-surface {
  font-family: "Poppins Bold";
  color: var(--inchcape-dark-blue) !important;
}

.usage-chart-cont {
  display: flex;
  flex-direction: column;
  background-color: var(--inchcape-white);
  padding: 50px;
  gap: 17px;
}

/* Usage report Grid */
.usage-grid-cont {
  overflow: hidden;
  overflow-x: auto;
}

.usage-grid-header {
  display: grid;
  grid-template-columns: repeat(5, minmax(0, 1fr));
  border: 1px solid rgba(0, 0, 0, 0.08);
  gap: 20px;
  padding: 15px;
  background-color: #fafafa;
}

.usage-grid-header p {
  margin: 0;
  font-family: "Poppins";
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--inchcape-label);
}

.usage-grid-header .k-picker-solid:hover {
  background: var(--inchcape-white);
}

.usage-grid-header .k-multiselect::after {
  content: "\25bc";
  display: inline-block;
  position: absolute;
  font-size: 10px;
  color: var(--inchcape-light-grey);
  z-index: 2;
  top: 23%;
  right: 3%;
  cursor: pointer;
}

.usage-grid-header .k-multiselect {
  caret-color: transparent;
}

.usage-grid-header .k-input-md .k-input-values > .k-input-inner {
  cursor: pointer;
  /* display: no; */
}

/* FILTER STYLING */
.usage-grid-header .k-multiselect .k-input-values {
  flex-wrap: nowrap;
  height: 30px;
}

.usage-grid-header .k-multiselect .k-input-inner {
  caret-color: transparent;
  width: 100%;
  position: absolute;
  background-color: transparent;
}

.usage-grid-header .k-clear-value,
.usage-grid-header .k-chip-actions {
  display: none;
}

.usage-grid-header .k-chip:hover {
  background: var(--inchcape-white);
}

.usage-grid-header .k-chip-list {
  width: 93%;
}

.usage-grid-header .k-chip {
  width: 100%;
}

/* .k-list-content:has(.reports-dropdown) {
  
  max-height: calc(100vh - 220px) !important;
  overflow-y: hidden;
} */

/* .k-multiselect-popup .k-list-ul:has(.reports-dropdown) {
  overflow-y: auto;
} */

/* daterange picker */
.usage-grid-header .daterange-picker {
  grid-column: 1 / 3;
}

.usage-grid-header .k-floating-label-container > .k-label {
  font-family: "Poppins" !important;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

.usage-grid-header .k-floating-label-container,
.usage-grid-header .k-dateinput,
.usage-grid-header .k-daterangepicker label {
  width: 100%;
}

.usage-grid-header > label {
  width: 50%;
}

.usage-grid-header .k-daterangepicker {
  gap: 20px;
}

.usage-grid-cont .k-grid {
  height: 45vh;
}

@media screen and (max-width: 1024px) {
  .usage-grid-header {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .usage-grid-header .daterange-picker {
    grid-column: 2/4;
  }

  .usage-chart-holder .k-chart {
    width: 390px !important;
  }
}
