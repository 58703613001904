/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

code {
  color: #e01a76;
}

/* Module container */
.module-cont {
  background: #fff;
  border-radius: 6px;
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.05);
}

/* Default Style Buttons */
.primary-btn {
  color: var(--inchcape-white);
  background-color: var(--inchcape-blue);
  border: 1px solid var(--inchcape-blue);
  box-shadow: 0px 8px 16px rgba(52, 175, 249, 0.2);
  border-radius: 4px;
  cursor: pointer;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.primary-btn:focus-visible,
.secondary-btn:focus-visible,
.bg-none-btn:focus-visible {
  outline: var(--inchcape-white);
}

.primary-btn:hover {
  background-color: #2dc6f0;
  border: 1px solid #2dc6f0;
  color: var(--inchcape-white);
  transition: all 0.3s ease;
}

.primary-btn:active {
  background-color: #14afda;
  border: 1px solid #14afda;
  color: var(--inchcape-white);
  transition: all 0.3s ease;
}

.primary-btn:disabled {
  cursor: default;
  color: var(--inchcape-white);
  background: #8f8f8f;
  box-shadow: 0px 4px 10px rgba(143, 143, 143, 0.24);
  border: 1px solid #8f8f8f;
}

.secondary-btn {
  background-color: transparent;
  color: var(--inchcape-blue);
  border: 1px solid var(--inchcape-blue);
  border-radius: 4px;
  cursor: pointer;
}

.secondary-btn:hover {
  background-color: var(--inchcape-blue);
  color: var(--inchcape-white);
  box-shadow: 0px 8px 16px rgba(52, 175, 249, 0.2);
  transition: all 0.3s ease;
}

.secondary-btn:active {
  background-color: #14afda;
  border: 1px solid #14afda;
  color: var(--inchcape-white);
  transition: all 0.3s ease;
}

.bg-none-btn {
  border: 1px solid var(--inchcape-white);
  background-color: var(--inchcape-white);
  color: var(--inchcape-blue);
  border-radius: 4px;
}

.bg-none-btn:hover {
  color: var(--inchcape-blue);
  background: rgba(22, 186, 231, 0.12);
  transition: all 0.3s ease-in-out;
}

.primary-filter-color {
  filter: invert(68%) sepia(99%) saturate(2833%) hue-rotate(151deg)
    brightness(99%) contrast(84%);
}

.secondary-filter-color {
  filter: invert(11%) sepia(24%) saturate(3444%) hue-rotate(174deg)
    brightness(95%) contrast(103%);
}

/* Default Style Inputs */
.primary-input {
  border-left: none;
  border-top: none;
  border-right: none;
  border-color: #e5e5e5e5;
  background-clip: padding-box;
  padding-left: 5px;
  font-size: 14px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
}

.primary-input:focus {
  outline: none;
}

/* Error Style */
.error-text {
  color: #f31700 !important;
  font-family: "Poppins" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 13px !important;
  line-height: 20px !important;
}

.error-input {
  border-bottom: 1px solid #f31700;
}

/* Modify radio button */
input[type="radio"] {
  /* Add if not using autoprefixer */
  -webkit-appearance: none;
  /* Remove most all native input styles */
  appearance: none;
  /* For iOS < 15 */
  border-color: var(--inchcape-blue) !important;
  background-color: var(--inchcape-white) !important;
  /* Not removed via appearance */
  margin: 0;
  font: inherit;
  width: 1.15em;
  height: 1.15em;
  border: 0.15em solid;
  border-radius: 50%;
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;
}

input[type="radio"]::before {
  content: "";
  width: 0.65em;
  height: 0.65em;
  border-radius: 50%;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em var(--form-control-color);
  background-color: var(--inchcape-blue);
}

input[type="radio"]:checked::before {
  transform: scale(1);
  outline: max(2px, 0.15em) solid var(--inchcape-blue);
  outline-offset: max(2px, 0.15em);
}

/* Headings */

.h1,
h2 {
  color: var(--inchcape-blue);
  font-family: "Poppins Bold";
  margin: 0;
}

.h1 {
  font-size: 24px;
}

h2 {
  font-size: 18px;
}

label {
  font-family: "Poppins";
  font-weight: bold;
}

/* Loader */
.loader-spinner {
  z-index: 2000;
  margin-top: 25%;
  margin-left: 50%;
  position: absolute;
}

.loader-modal-spinner {
  position: fixed;
  z-index: 1000;
  left: 50%;
  top: 50%;
}

.loader-background {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: white;
  z-index: 1000;
  opacity: 50%;
}

.k-input-flat:focus,
.k-input-flat:focus-within {
  box-shadow: none;
}

/* Switch */
.k-switch-on .k-switch-track {
  border-color: var(--inchcape-blue);
  background-color: var(--inchcape-blue);
}

.k-switch-md {
  width: 70px;
}

.k-switch-label-on,
.k-switch-label-off {
  font-family: "Poppins";
  font-size: 11px;
}

.k-switch-label-off {
  right: 10px !important;
}

.k-switch-label-on {
  left: 10px !important;
}

/* Checkbox */
.k-checkbox:checked,
.k-checkbox.k-checked {
  border-color: var(--inchcape-blue);
  background-color: var(--inchcape-blue) !important;
}

.k-checkbox:checked:focus,
.k-checkbox.k-checked.k-focus {
  box-shadow: 0 0 0 2px #43d6ff6d;
}

.k-checkbox:checked:focus {
  box-shadow: none;
}

/* Multiselect */
.k-multiselect .k-input-values {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

.k-chip {
  border: none;
  background: var(--inchcape-white);
}

.k-chip:hover {
  background: #16bae775;
}

/* Calendar */
.k-calendar,
.k-button-flat-primary,
.k-calendar-view,
.k-calendar .k-calendar-view .k-today,
.k-calendar-nav-today {
  color: var(--inchcape-blue) !important;
}

.k-calendar .k-calendar-td.k-selected .k-link,
.k-calendar-td .k-link:hover,
.k-calendar .k-calendar-td:hover .k-calendar-cell-inner,
.k-calendar .k-calendar-td:hover .k-link,
.k-calendar .k-calendar-td.k-hover .k-calendar-cell-inner,
.k-calendar .k-calendar-td.k-hover .k-link {
  color: var(--inchcape-white);
  background-color: var(--inchcape-blue);
  border-color: var(--inchcape-blue);
}

.k-calendar .k-calendar-td.k-selected:hover .k-link,
.k-calendar .k-calendar-navigation li:hover {
  color: var(--inchcape-white);
  background-color: #2dc6f0;
}

.k-calendar .k-range-split-start::after {
  background-image: linear-gradient(
    to left,
    rgba(22, 186, 231, 0.3),
    transparent 100%
  );
}

.k-calendar .k-range-split-end::after {
  background-image: linear-gradient(
    to right,
    rgba(22, 186, 231, 0.3),
    transparent 100%
  );
}

.k-calendar .k-range-start,
.k-calendar .k-range-end,
.k-calendar .k-range-mid {
  background-color: rgba(22, 186, 231, 0.3);
}

/* Grid */
.grid-container {
  height: 100%;
  width: 100%;
}

.k-grid,
.k-grid-content {
  border-color: var(--inchcape-white);
}

.k-grid-content {
  overflow: auto;
  position: relative;
}

.k-loader {
  color: var(--inchcape-blue);
}

.k-grid-header {
  padding-inline-end: 0;
}

.k-table-th,
.k-table-td {
  white-space: normal;
  font-size: 14px;
  border: none;
}

.k-grid th {
  border-width: 0 0 1px 0;
}

.k-grid td {
  background: var(--inchcape-white);
  border: none;
}

.k-table-th {
  font-family: "Poppins Bold";
  color: rgba(0, 35, 58, 0.6);
}

.k-table-td {
  font-family: "Poppins";
  color: rgba(0, 35, 58, 0.9);
}

.k-table-tbody .k-table-row.k-selected,
.k-table-list .k-table-row.k-selected,
.k-grid td.k-selected,
.k-grid tr.k-selected > td {
  background-color: var(--inchcape-white);
}

/* Pagination */
.k-grid-pager,
.k-pager {
  justify-content: center;
  font-family: "Poppins";
  border: none;
}

.k-pager-wrap,
.k-pager {
  background: var(--inchcape-white);
  width: 100%;
  flex-wrap: wrap;
}

.k-pager > * {
  margin-bottom: 5px;
}

.k-pager-info {
  display: flex !important;
  justify-content: flex-start;
  flex: 0;
}

.k-pager-numbers-wrap .k-dropdown-list {
  display: none !important;
}

.k-pager-numbers-wrap .k-pager-numbers {
  display: flex !important;
}

/* Dialog Kendo Styling */
.k-dialog-wrapper .k-dialog {
  border-radius: 6px !important;
}

/* Kendo Animation Styling */
.k-animation-container {
  z-index: 10005 !important ;
}

/* Dropdown Kendo Styling */
.k-picker-solid {
  border-top: none;
  border-left: none;
  border-right: none;
  background: var(--inchcape-white);
}

.k-multiselect-popup .k-list-ul,
.k-dropdownlist-popup .k-list-ul {
  max-height: calc(100vh - 220px) !important;
  overflow-x: hidden;
  overflow-y: hidden;
}

.k-list-content:has(.status-items) {
  max-height: 540px !important;
  /* overflow-y: hidden; */
}

.k-picker-solid:hover {
  background: none;
}

.k-picker-solid:focus-within {
  box-shadow: none;
}

.k-input-value-text,
.k-list-item {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

.k-picker-solid,
.k-input-md,
.k-picker-md,
.k-input-md .k-input-inner,
.k-input-solid:focus {
  font-family: "Poppins";
  background: var(--inchcape-white);
}

.k-input-inner::placeholder {
  color: var(--inchcape-light-grey);
}

.k-list-item:hover,
.k-list-optionlabel:hover,
.k-list-item.k-hover,
.k-hover.k-list-optionlabel,
.k-list-item.k-selected,
.k-selected.k-list-optionlabel,
.k-list-item.k-selected:hover,
.k-radio:checked,
.k-radio:checked:focus {
  color: var(--inchcape-white);
  background-color: var(--inchcape-blue);
}

.k-list-item:focus,
.k-list-optionlabel:focus,
.k-list-item.k-focus,
.k-focus.k-list-optionlabel,
.k-radio:checked,
.k-radio:checked:focus {
  box-shadow: none;
}

/* Progress Bars */
.k-progressbar .k-selected {
  border-color: var(--inchcape-blue);
  color: white;
  background-color: var(--inchcape-blue);
}

.k-progressbar-horizontal {
  height: 8px;
}

/* UTIL STATUS TEXT */
.first-status {
  text-align: center;
  color: var(--inchcape-purple);
  font-family: "Poppins Bold";
  font-weight: 500;
  font-size: 13px;
  line-height: 17px;
  letter-spacing: 0.01em;

  /* padding: 5px 9px; */
}

.second-status {
  text-align: center;
  color: var(--inchcape-red);
  font-family: "Poppins Bold";
  font-weight: 500;
  font-size: 13px;
  line-height: 17px;
  letter-spacing: 0.01em;
  /* padding: 5px 9px; */
}

.third-status {
  text-align: center;
  color: var(--inchcape-green);
  font-family: "Poppins Bold";
  font-weight: 500;
  font-size: 13px;
  line-height: 17px;
  letter-spacing: 0.01em;
}

.fourth-status {
  text-align: center;
  color: var(--inchcape-yellow);
  font-family: "Poppins Bold";
  font-weight: 500;
  font-size: 13px;
  line-height: 17px;
  letter-spacing: 0.01em;
}

.fifth-status {
  text-align: center;
  color: var(--inchcape-blue);
  font-family: "Poppins Bold";
  font-weight: 500;
  font-size: 13px;
  line-height: 17px;
  letter-spacing: 0.01em;
}

.sixth-status {
  text-align: center;
  color: var(--inchcape-dark-blue);
  font-family: "Poppins Bold";
  font-weight: 500;
  font-size: 13px;
  line-height: 17px;
  letter-spacing: 0.01em;
}

/* UTIL STATUS BOX */
.bg-first-status {
  background-color: var(--inchcape-purple);
  font-family: "Poppins Bold";
  font-weight: 500;
  font-size: 13px;
  line-height: 17px;
  letter-spacing: 0.01em;
  color: #fff;
  /* padding: 5px 9px; */
}

.bg-second-status {
  color: var(--inchcape-red);
  font-family: "Poppins Bold";
  font-weight: 500;
  font-size: 13px;
  line-height: 17px;
  letter-spacing: 0.01em;
  text-align: center;
  /* padding: 5px 9px; */
}

.third-status {
  color: var(--inchcape-green);
  font-family: "Poppins Bold";
  font-weight: 500;
  font-size: 13px;
  line-height: 17px;
  letter-spacing: 0.01em;
}

.fourth-status {
  color: var(--inchcape-blue);
  font-family: "Poppins Bold";
  font-weight: 500;
  font-size: 13px;
  line-height: 17px;
  letter-spacing: 0.01em;
}

.fifth-status {
  color: var(--inchcape-grey);
  font-family: "Poppins Bold";
  font-weight: 500;
  font-size: 13px;
  line-height: 17px;
  letter-spacing: 0.01em;
}

.sixth-status {
  color: var(--inchcape-dark-blue);
  font-family: "Poppins Bold";
  font-weight: 500;
  font-size: 13px;
  line-height: 17px;
  letter-spacing: 0.01em;
}

/* FUNNEL COLOR */
.blue-status {
  background-color: var(--inchcape-blue);
  color: #09101d;
}

.green-status {
  background-color: var(--inchcape-green);
  color: #09101d;
}

.purple-status {
  background-color: var(--inchcape-purple);
  color: var(--inchcape-white);
}

.indigo-status {
  background-color: #7887fb;
  color: var(--inchcape-white);
}

.dark-status {
  background-color: var(--inchcape-dark-blue);
  color: var(--inchcape-white);
}

.k-sort-icon .k-icon {
  color: var(--inchcape-blue);
}

/* Scrollbar */
/* width */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--inchcape-white);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(0, 35, 58, 0.1);
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 35, 58, 0.1);
}
