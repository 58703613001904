.customer-record {
  width: 100%;
  grid-area: 1 / 1 / -1 / -1;
}

.customer-record .header-button {
  padding: 0 0.7rem;
  font-family: "Poppins";
  font-size: 14px;
}

.customer-record .header label {
  font-size: 14px;
  color: rgba(0, 35, 58, 0.6);
}

.customer-record .offset-1 {
  margin-left: 6%;
}

.customer-record .k-sort-icon .k-icon {
  color: var(--inchcape-blue);
}

.customer-record .k-icon {
  background-color: none;
}

.customer-record .primary-btn {
  padding: 11px 15px;
  font-family: "Poppins";
  font-size: 13px;
  font-weight: 600;
}

.customer-record .opportunity-btn {
  margin-top: 65px;
}

.customer-record .k-hbox {
  border: none;
}

.customer-record .k-card-body {
  margin-top: -20px;
  padding: 25px;
}

.customer-record .k-column-title {
  white-space: pre-wrap;
  max-width: fit-content;
}

.customer-record .k-table-td {
  width: auto;
  white-space: pre-wrap;
  overflow: visible;
  text-overflow: clip;
}

.customer-record .k-card-title {
  font-family: "Poppins Bold";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  letter-spacing: 0.01em;
  color: #16bae7;
  white-space: pre-wrap;
  margin-top: 10px;
}

.customer-record .k-card-subtitle {
  font-family: "Poppins Bold";
  font-weight: 500;
  font-size: 11px;
  line-height: 14px;
  letter-spacing: 0.01em;
  color: rgba(0, 35, 58, 0.6);
}

.customer-record .k-flex-20 .k-grid-header {
  background-color: rgb(255, 255, 255);
  border: none;
}

.customer-record .k-grid-header-wrap {
  border: none;
}

.customer-record .k-grid-header {
  background-color: #fff;
}

.customer-record .k-link {
  background-color: rgb(255, 255, 255);
  border: none;
}

.customer-record .k-header {
  background-color: rgb(255, 255, 255);
  border-bottom: none;
}

.customer-record .k-grid-table-wrap {
  background-color: rgb(255, 255, 255);
}

.customer-record .campaign-name {
  white-space: pre-wrap;
}

.customer-record .contact-method {
  border: none;
  background-color: none;
  max-width: 100%;
}

.customer-record .k-grid tbody tr {
  border: none;
}

.customer-record .k-grid-td {
  border-top: none;
}

.customer-record .k-checkbox:checked {
  background-color: #16bae7;
}

.customer-record .status-cell {
  padding: 5px 9px;
  font-size: 12px;
  display: flex;
  flex-wrap: nowrap; /* Prevent wrapping */
  align-items: center; /* Vertically center elements */
  gap: 3rem; /* Adjust the spacing between elements as needed */
  border-radius: 2px;
}

.customer-record .dropdown-container {
  width: 170%;
  margin-bottom: 25px;
}

.customer-record .dropdown {
  width: 100%;
  max-height: "50px";
  overflow-y: "auto";
}

.customer-record .datePicker {
  border-bottom: 1px solid #e5e9eb;
  border-left: none;
  border-top: none;
  border-right: none;
  border-radius: none;
}

.customer-record .textArea {
  margin-top: 25px !important;
}

.customer-record .update-btn {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  float: right !important;
  align-items: center;
  padding: 11px 32px;
  width: auto;
  height: 39px;
  background: #16bae7;
  color: white;
  margin-top: 20px;
}

.customer-record .activity-record {
  margin-top: 80px;
}

.customer-record .activity-log {
  border: 1px solid rgba(0, 35, 58, 0.1);
  border-color: solid 1px #e5e9eb;
  border-radius: 6px;
  width: 100%;
  height: 375px;
  overflow-y: auto;
  margin-top: 25px;
  margin-bottom: 23px;
}

.customer-record .activity-log-output {
  float: right;
  border-radius: 6px;
  height: auto;
  background-color: #e8f8fd;
  margin: 10px;
  display: flex;
  padding: 15px;
  flex-direction: column;
  margin-bottom: 1px;
  width: 240px;
}

.customer-record .activity-opportunity {
  float: left;
  border-radius: 6px;
  background-color: #f2f4f5;
  height: auto;
  margin: 10px;
  display: flex;
  padding: 15px;
  flex-direction: column;
  margin-bottom: 1px;
  width: 240px;
}

.customer-record .activity-opportunity-header {
  font-family: "Poppins";
  color: #16bae7;
  margin-bottom: 1px;
}

.customer-record .activity-log-lbl {
  font-weight: normal !important;
}

.customer-record .description {
  margin-top: 5px;
}

.customer-record .editDeal-btn {
  align-items: center;
  width: auto;
  height: 42px;
  margin-right: 25px;
  margin-left: -10px;
}

.customer-record .deskingSheet-btn {
  align-items: center;
  width: auto;
  height: 42px;
  box-sizing: border-box;
  gap: 10px;
  border: 1px solid #16bae7;
  border-radius: 4px;
  font-family: "Poppins Bold";
  margin-bottom: 25px;
  padding: 5px;
}

.customer-record .rowCell {
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  border-left: none !important;
}

.customer-record .financeRecordTableGrid {
  border: none !important;
  font-family: "Poppins";
}

.customer-record .tableHeaderCell {
  background-color: #fff;
  color: #00233a99;
  font-weight: 500;
  width: 100%;
}

.customer-record .k-grid-content {
  height: auto;
  overflow-x: scroll;
  overflow-y: scroll;
}

.customer-record .grid-container {
  height: 100%;
  transition: margin-left 0.3s; /* Add a transition for smooth animation */
}

.customer-record .grid-container-expanded {
  margin-left: 200px; /* Adjust the margin as per your needs */
}

.k-grid-header-wrap .k-table {
  width: 100% !important;
}

.k-grid-table-wrap .k-table {
  width: 100% !important;
}

.desking-sheet .k-table {
  width: auto !important; /* Set the desired width or any other property you need */
}

.customer-record .grid-wrapper {
  height: calc(
    100% - 60px
  ); /* Adjust the height to leave space for the buttons */
  overflow: auto;
}

.customer-record .headerTitle {
  margin-bottom: 0px !important;
  font-family: "Poppins Bold" !important;
  font-size: 16px;
}

.customer-record .proposedHeaderTitle {
  color: #16bae7;
}

.customer-record .expandedDetails {
  background: rgba(22, 186, 231, 0.07);
}

.customer-record .monthlyPayment {
  font-family: "Poppins Bold" !important;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  text-align: left;
  letter-spacing: 0.01em;
  color: #16bae7;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.customer-record .monthlyPaymentAmount {
  font-family: "Poppins Bold" !important;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  text-align: left;
  letter-spacing: 0.01em;
  color: rgba(0, 35, 58, 0.9);
  flex: none;
  order: 0;
  flex-grow: 0;
}

.customer-record .proposedRow {
  font-family: "Poppins" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  text-align: left;
  letter-spacing: 0.01em;
  color: #16bae7;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.customer-record .expandedDetailsRow {
  margin-left: 10px;
}

.customer-record .row {
  width: 100%;
  font-family: "Poppins" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  text-align: left;
  letter-spacing: 0.01em;
  color: #00233ae5;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.customer-record .caretIconBtn {
  display: flex-end;
  border: none;
  background-color: #ffff;
  float: right;
  width: 1px;
  position: absolute;
  margin-left: 150px;
}

.customer-record .datePicker {
  border-left: none !important;
  border-top: none !important;
  border-right: none !important;
  border-bottom: 2px solid !important;
  border-color: #e5e5e5e5 !important;
  background-clip: padding-box;
  background-color: #fff;
  border-radius: 0px;
  outline: none;
  box-shadow: none;
}

.customer-record .datePicker > button {
  border: none !important;
  background-color: #fff;
}

.customer-record .k-input:focus,
.k-input:active,
.k-input:hover {
  outline: none;
  box-shadow: none;
}

/* UTIL STATUS BOX */
.customer-record .status-common {
  justify-content: center;
  text-align: center;
  font-family: "Poppins Bold";
  font-weight: 500;
  font-size: 13px;
  line-height: 17px;
  letter-spacing: 0.01em;
  padding: 2px 2px;
  border-radius: 2px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.03), 0px 4px 5px rgba(0, 0, 0, 0.04);
}

.customer-record .first-status {
  color: var(--inchcape-white);
  background-color: var(--inchcape-purple);
}

.customer-record .second-status {
  color: var(--inchcape-white);
  background-color: var(--inchcape-red);
}

.customer-record .third-status {
  color: var(--inchcape-dark-blue);
  background-color: var(--inchcape-green);
}

.customer-record .fourth-status {
  color: var(--inchcape-dark-blue);
  background-color: var(--inchcape-blue);
}

.customer-record .fifth-status {
  color: var(--inchcape-white);
  background-color: var(--inchcape-grey);
}

.customer-record .sixth-status {
  color: var(--inchcape-white);
  background-color: var(--inchcape-dark-blue);
}

.customer-record .full-width {
  display: block;
  width: 100%;
  padding: 5px;
  border-radius: 2px;
  margin-bottom: 5px;
  margin-top: -15px;
  text-align: center;
}

.customer-record .break {
  height: 20px;
}

.customer-record .back-btn {
  cursor: pointer;
}
