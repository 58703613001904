.import-vehicle-title {
  font-family: "Poppins Bold";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 31px;
  color: var(--inchcape-blue);
  text-align: center;
}

.close-icon {
  cursor: pointer;
}

.k-window-content:has(.import-vehicle-box) {
  width: 420px;
  height: auto;
  text-align: center;
  background: var(--inchcape-white);
  border-radius: 6px;
}

.k-window-content:has(.import-progress-box) {
  width: 360px !important;
}

.import-vehicle-box .success-icon {
  color: var(--inchcape-blue);
}

.import-vehicle-box svg {
  width: 60px;
  height: auto;
  color: #959090;
}

.import-progress-box svg {
  width: 60px;
  height: auto;
  color: #959090;
}

.import-vehicle-box button {
  font-family: "Poppins";
  font-size: 13px;
  padding: 11px 28px;
}

.import-vehicle-box a {
  color: var(--inchcape-dark-blue);
}

.import-vehicle-box a:hover {
  color: var(--inchcape-blue);
}

.template {
  font-family: "Poppins";
  font-size: 12px;
}

.msg {
  font-family: "Poppins";
  color: var(--inchcape-label);
}

.msg-error {
  font-family: "Poppins";
  color: var(--inchcape-red);
}

.msg-success {
  font-family: "Poppins";
  color: var(--inchcape-green);
}

.import-input {
  border: 1px solid grey;
  height: 30px;
}

progress::-moz-progress-bar {
  background: var(--inchcape-blue);
}

progress::-webkit-progress-value {
  background: var(--inchcape-blue);
}

progress {
  width: 80%;
  background: var(--inchcape-blue);
  height: 10px;
  border-radius: 3px;
  margin-bottom: 6px;
}

.bg-none-btn:disabled,
.bg-none-btn:hover:disabled {
  color: var(--inchcape-label);
  background: transparent;
}

.close-icon-disabled {
  pointer-events: none;
}

input[type="file"]::file-selector-button {
  border: none;
  height: 100%;
}

.error-title {
  font-family: "Poppins Bold";
  color: gray;
  font-size: 18px;
  line-height: 1.8rem;
}

.successful-title {
  font-family: "Poppins Bold";
  color: var(--inchcape-blue);
  font-size: 20px;
  line-height: 1.8rem;
}
