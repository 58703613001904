.loading {
  position: absolute;
  z-index: 99999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading.full {
  background-color: #fbfbfbc0;
  top: -1.3rem;
  right: -2.5rem;
  left: -2.5rem;
  bottom: -2.5rem;
  grid-area: 1 / 1 / -1 / -1;
  min-height: 100vh;
}

.loading.grid {
  background-color: #fff;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  height: 100%;
}

.loading.grid-light-background {
  background-color: #fbfbfbc0;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  height: 100%;
}
