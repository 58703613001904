.vehicles-container {
  width: 100%;
  grid-area: 1 / 1 / -1 / -1;
}

.vehicles-container .header {
  padding: 1.5rem;
}

.vehicles-container .header button {
  padding: 0 0.7rem;
  font-family: "Poppins";
  font-size: 14px;
  box-shadow: 0px 3px 6px rgba(16, 156, 241, 0.24);
}

.vehicles-container .header > button {
  padding: 11px 32px;
}

.vehicles-container .header label {
  font-size: 14px;
  color: rgba(0, 35, 58, 0.6);
}

.vehicles-container .k-grid-container {
  height: 64vh;
}

.vehicles-container .offset-1 {
  margin-left: 6%;
}

.vehicles-container .k-table-row .k-button {
  background: #f5f5f5;
  border: 1px solid rgba(0, 0, 0, 0.08);
}

.vehicles-container .k-table {
  width: 100% !important;
}

.vehicles-container .actions-cell {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-evenly;
}

.vehicles-container .edit-icon,
.vehicles-container .delete-icon {
  cursor: pointer;
}

.vehicles-container .status-circle-red {
  height: 20px;
  width: 20px;
  background-color: #ff2400;
  border-radius: 50%;
  display: inline-block;
}

.vehicles-container .status-circle-yellow {
  height: 20px;
  width: 20px;
  background-color: #fada5e;
  border-radius: 50%;
  display: inline-block;
}

.vehicles-container .status-circle-green {
  height: 20px;
  width: 20px;
  background-color: #66ff00;
  border-radius: 50%;
  display: inline-block;
}
