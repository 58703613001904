.settings {
  width: 100%;
  grid-area: 1 / 1 / -1 / -1;
}

.settings .module-cont {
  padding: 23px 33px;
  display: flex;
  flex-direction: column;
  gap: 25px;
  align-items: flex-start;
}

.settings label {
  color: rgba(0, 35, 58, 0.6);
  font-size: 14px;
}

.settings .module-cont p {
  font-size: 12px;
  font-family: "Poppins";
  margin-top: 0.4rem;
}

.settings .module-cont .settings-language-dropdown {
  width: 20rem;
}

.settings .error label,
.settings .error p {
  color: var(--inchcape-red);
}

.settings .error .k-input-flat {
  border-bottom: 1px solid var(--inchcape-red);
}

.settings button {
  padding: 11px 32px;
  font-family: "Poppins Bold";
  font-size: 13px;
}
