.bulk-actions .k-dialog-content {
  width: min(60vw, 310px);
}

.bulk-actions .btn-group button {
  flex: 1;
  font-family: "Poppins";
  padding: 0.8rem;
}

.bulk-actions .inputs {
  gap: 3rem;
}

.bulk-actions label {
  color: rgba(0, 35, 58, 0.6);
}

.bulk-actions img,
.portfolio-filter img {
  cursor: pointer;
}

.bulk-actions .custom.k-input:focus,
.bulk-actions .custom.k-input-md {
  outline: none;
  box-shadow: none;
}

.portfolio-filter .k-dialog-content {
  width: min(83vw, 900px);
  padding: 1.5rem 2rem 1.5rem 1.8rem;
  max-height: 90vh;
}

.portfolio-filter .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2.5rem;
}

.portfolio-filter h1 {
  font-size: 18px;
  padding-left: 0.9rem;
}

.portfolio-filter .k-multiselect,
.portfolio-filter .k-dropdownlist,
.portfolio-filter .activity-type .k-input {
  border: 1px solid rgba(66, 66, 66, 0.5);
}

.portfolio-filter .col-md-6 {
  padding: 0 1.5rem;
}

.portfolio-filter .columns {
  gap: 2vw;
  margin-bottom: 4.5vh;
}

.portfolio-filter .columns > * {
  flex: 1;
}

.portfolio-filter label {
  margin-bottom: 10px;
  color: var(--inchcape-dark-blue);
  display: block;
}

.portfolio-filter .k-input::placeholder {
  font-family: "Poppins Light";
  opacity: 0.5;
}

.portfolio-filter .k-multiselect input::placeholder,
.portfolio-filter .k-dropdownlist input::placeholder,
.portfolio-filter .overdue {
  color: #424242;
  font-family: "Poppins Light";
}

.portfolio-filter .overdue p {
  padding-top: 4px;
}

.portfolio-filter .activity-type {
  align-items: center;
}

.portfolio-filter .activity-type > p {
  width: 10%;
}

.portfolio-filter .activity-type > input {
  width: 25%;
  margin-right: 1rem;
}

.portfolio-filter .activity-type > span {
  width: 40%;
}

.portfolio-filter p {
  margin: 0;
}

.k-multiselect.custom::after {
  top: 25% !important;
}

.portfolio-filter .lead-type .k-dropdownlist {
  width: 48%;
}

.portfolio-filter .btn-group {
  display: flex;
  gap: 2rem;
}

.portfolio-filter .btn-group > * {
  padding: 0.75rem 2rem;
  font-family: "Poppins";
  font-size: 13px;
}

.portfolio-filter .k-multiselect .k-input-values {
  flex-wrap: nowrap;
}

.portfolio-filter .k-multiselect .k-input-inner {
  caret-color: transparent;
}

.portfolio-filter .k-multiselect .k-i-x-circle,
.portfolio-filter .k-clear-value {
  display: none;
}

.portfolio-filter .k-input-solid.k-invalid {
  border-color: rgba(66, 66, 66, 0.08);
}
