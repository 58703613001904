/* Header Container */
.header-container {
  /* gap: 30px; */
  grid-area: 1 / 2 / 2 / -1;
  z-index: 99;
}

.header-label {
  font-family: "Poppins Bold";
  font-weight: 500;
  font-size: 13px;
  line-height: 17px;
  color: rgba(0, 35, 58, 0.6);
}

/* Language Wrapper */
.language-wrapper,
.country-wrapper,
.branch-wrapper {
  border: none;
  background: transparent;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 6px;
}

.language-wrapper,
.country-wrapper {
  margin-right: 30px;
}

.language-wrapper:hover,
.country-wrapper:hover .branch-wrapper:hover {
  color: var(--inchcape-blue);
  filter: brightness(0) saturate(100%) invert(50%) sepia(89%) saturate(3000%)
    hue-rotate(160deg) brightness(93%) contrast(150%);
}

.language-wrapper:focus-visible,
.country-wrapper:focus-visible,
.branch-wrapper:focus-visible {
  outline: none;
  color: var(--inchcape-blue);
  filter: brightness(0) saturate(100%) invert(50%) sepia(89%) saturate(3000%)
    hue-rotate(160deg) brightness(93%) contrast(150%);
}

.language-dialog-box {
  width: 299px;
  height: 240px;
}

.dialog-title {
  display: block;
  font-family: "Poppins Bold";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 31px;
  color: var(--inchcape-blue);
  text-align: center;
  padding: 24px 24px;
}

.language-dropdown,
.branch-dropdown {
  margin-bottom: 26px;
  padding: 21px 4px 20px;
  padding-top: 21px;
  padding-left: 4px;
}

.market-dropdown {
  padding: 21px 4px 20px;
  padding-top: 21px;
  padding-left: 4px;
}

.language-dropdown p,
.market-dropdown p,
.branch-dropdown p {
  margin: 0;
  padding-bottom: 2px;
  font-family: "Poppins Bold";
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: rgba(0, 35, 58, 0.6);
}

.market-dropdown-list {
  max-height: 150px;
  overflow-y: auto;
}

/* Market Styling */
.market-dialog-box {
  width: 274px;
  height: 364px;
}

.dialog-footer {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.dialog-btn {
  font-family: "Poppins";
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.01em;
  padding: 11px 32px;
  border-radius: 4px;
}
