.duplicate-campaign-box {
  width: 272px;
  height: 244px;
}

.duplicate-campaign h1 {
  text-align: center;
}

.buttons button p {
  font-family: "Poppins";
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.01em;
  border: none;
  padding: 11px 32px;
  border-radius: 4px;
}

.delete-campaign-box {
  width: 252px;
}
