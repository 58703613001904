.desking-sheet {
    width: 100%;
    grid-area: 1 / 1 / -1 / -1;
  }
  
  .desking-sheet .header-button {
    padding: 0 0.7rem;
    font-family: "Poppins";
    font-size: 14px;
  }
  
  .desking-sheet .header label {
    font-size: 14px;
    color: rgba(0, 35, 58, 0.6);
  }
  
  .desking-sheet .offset-1 {
    margin-left: 6%;
  }
  
  .desking-sheet .k-sort-icon .k-icon {
    color: var(--inchcape-blue);
  }
  
  .desking-sheet .k-icon {
    background-color: none;
  }
  
  .desking-sheet .primary-btn {
    padding: 11px 15px;
    font-family: "Poppins";
    font-size: 13px;
    font-weight: 600;
  }
  
  .desking-sheet .opportunity-btn {
    margin-top: 65px;
  }
  
  .desking-sheet .k-hbox {
    border: none;
  }
  
  .desking-sheet .k-card-body {
    margin-top: -20px;
    padding: 25px;
  }
  
  .desking-sheet .k-column-title {
    white-space: pre-wrap;
    max-width: fit-content;
  }
  
  .desking-sheet .k-table-td {
    width: auto;
    white-space: pre-wrap;
    overflow: visible;
    text-overflow: clip;
  }
  
  .desking-sheet .k-card-title {
    font-family: "Poppins Bold";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
    letter-spacing: 0.01em;
    color: #16bae7;
    white-space: pre-wrap;
    margin-top: 10px;
  }
  
  .desking-sheet .k-card-subtitle {
    font-family: "Poppins Bold";
    font-weight: 500;
    font-size: 11px;
    line-height: 14px;
    letter-spacing: 0.01em;
    color: rgba(0, 35, 58, 0.6);
  }
  
  .desking-sheet .k-flex-20 .k-grid-header {
    background-color: rgb(255, 255, 255);
    border: none;
  }
  
  .desking-sheet .k-grid-header-wrap {
    border: none;
  }
  
  .desking-sheet .k-grid-header {
    background-color: #fff;
  }
  
  .desking-sheet .k-link {
    background-color: rgb(255, 255, 255);
    border: none;
  }
  
  .desking-sheet .k-header {
    background-color: rgb(255, 255, 255);
    border-bottom: none;
  }
  
  .desking-sheet .k-grid-table-wrap {
    background-color: rgb(255, 255, 255);
  }
  
  .desking-sheet .campaign-name {
    white-space: pre-wrap;
  }
  
  .desking-sheet .contact-method {
    border: none;
    background-color: none;
    max-width: 100%;
  }
  
  .desking-sheet .k-grid tbody tr {
    border-bottom: 0.5px solid rgba(0, 0, 0, 0.08);
  }
  
  .desking-sheet .k-grid-td {
    border-top: 0.5px solid rgba(0, 0, 0, 0.08);
  }
  
  .desking-sheet .k-checkbox:checked {
    background-color: #16bae7;
  }
  
  .desking-sheet .status-cell {
    padding: 5px 9px;
    font-size: 12px;
    display: flex;
    flex-wrap: nowrap; /* Prevent wrapping */
    align-items: center; /* Vertically center elements */
    gap: 3rem; /* Adjust the spacing between elements as needed */
    border-radius: 2px;
  }
  
  .desking-sheet .dropdown-container {
    width: 170%;
    margin-bottom: 25px;
  }
  
  .desking-sheet .dropdown {
    width: 100%;
    max-height: "50px";
    overflow-y: "auto";
  }
  
  .desking-sheet .datePicker {
    border-bottom: 1px solid #e5e9eb;
    border-left: none;
    border-top: none;
    border-right: none;
    border-radius: none;
  }
  
  .desking-sheet .textArea {
    margin-top: 25px !important;
  }
  
  .desking-sheet .update-btn {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    float: right !important;
    align-items: center;
    padding: 11px 32px;
    width: auto;
    height: 39px;
    background: #16bae7;
    color: white;
    margin-top: 20px;
  }
  
  .desking-sheet .activity-record {
    margin-top: 80px;
  }
  
  .desking-sheet .activity-log {
    border: 1px solid rgba(0, 35, 58, 0.1);
    border-color: solid 1px #e5e9eb;
    border-radius: 6px;
    width: 100%;
    height: 375px;
    overflow-y: auto;
    margin-top: 25px;
    margin-bottom: 23px;
  }
  
  .desking-sheet .activity-log-output {
    float: right;
    border-radius: 6px;
    height: auto;
    background-color: #e8f8fd;
    margin: 10px;
    display: flex;
    padding: 15px;
    flex-direction: column;
    margin-bottom: 1px;
    width: 240px;
  }
  
  .desking-sheet .activity-opportunity {
    float: left;
    border-radius: 6px;
    background-color: #f2f4f5;
    height: auto;
    margin: 10px;
    display: flex;
    padding: 15px;
    flex-direction: column;
    margin-bottom: 1px;
    width: 240px;
  }
  
  .desking-sheet .activity-opportunity-header {
    font-family: "Poppins";
    color: #16bae7;
    margin-bottom: 1px;
  }
  
  .desking-sheet .activity-log-lbl {
    font-weight: normal !important;
  }
  
  .desking-sheet .description {
    margin-top: 5px;
  }
  
  .desking-sheet .editDeal-btn {
    align-items: center;
    width: auto;
    height: 42px;
    margin-right: 25px;
    margin-left: -10px;
  }
  
  .desking-sheet .deskingSheet-btn {
    align-items: center;
    width: auto;
    height: 42px;
    box-sizing: border-box;
    gap: 10px;
    border: 1px solid #16bae7;
    border-radius: 4px;
    font-family: "Poppins Bold";
    margin-bottom: 25px;
    padding: 5px;
  }
  
  .desking-sheet .rowCell {
    border-left: none !important;
    border-bottom: 0.5px solid rgba(0, 0, 0, 0.08);
    font-size: 8px;
    padding-top: 2px;
    padding-bottom: 0px;
    padding-right: 0px;
  }
  
  .desking-sheet .financeRecordTableGrid {
    border: none !important;
    font-family: "Poppins";
  }
  
  .desking-sheet .tableHeaderCell {
    background-color: #fff;
    color: #00233a99;
    font-weight: 500;
    width: 100%;
  }
  
  .desking-sheet .k-grid-content {
    height: auto;
    overflow-x: scroll;
    overflow-y: scroll;
  }
  
  .desking-sheet .grid-container {
    height: 100%;
    transition: margin-left 0.3s; /* Add a transition for smooth animation */
  }
  
  .desking-sheet .grid-container-expanded {
    margin-left: 200px; /* Adjust the margin as per your needs */
  }
  
  .desking-sheet .grid-wrapper {
    height: calc(
      100% - 60px
    ); /* Adjust the height to leave space for the buttons */
    overflow: auto;
  }
  
  .desking-sheet .headerTitle {
    margin-bottom: 0px !important;
    font-family: "Poppins Bold" !important;
    font-size: 16px;
  }
  
  .desking-sheet .proposedHeaderTitle {
    color: #16bae7;
  }
  
  .desking-sheet .expandedDetails {
    background: rgba(22, 186, 231, 0.07);
  }
  
  .desking-sheet .row-highlight {
    width: 100%;
    font-family: "Poppins Bold" !important;
    font-style: normal;
    font-weight: 400;
    font-size: 8px;
    text-align: left;
    letter-spacing: 0.01em;
    color: #16bae7;
    flex: none;
    order: 0;
    flex-grow: 0;
    padding: 0;
  }

  .desking-sheet .row-highlight-label {
    margin-left: -10px;
  }

  .desking-sheet .monthlyPaymentAmount {
    font-family: "Poppins Bold" !important;
    font-weight: 500;
    font-size: 8px;
    color: rgba(0, 35, 58, 0.9);
    margin-left: -10px;
  }
  
  .desking-sheet .proposedRow {
    font-family: "Poppins" !important;
    font-style: normal;
    font-weight: 400;
    font-size: 8px;
    text-align: left;
    letter-spacing: 0.01em;
    color: #16bae7;
    flex: none;
    order: 0;
    flex-grow: 0;
  }
  
  .desking-sheet .expandedDetailsRow {
    margin-left: 10px;
  }
  
  .desking-sheet .row {
    width: 100%;
    font-family: "Poppins" !important;
    font-style: normal;
    font-weight: 400;
    font-size: 8px;
    text-align: left;
    letter-spacing: 0.01em;
    color: #00233ae5;
    flex: none;
    order: 0;
    flex-grow: 0;
  }
  
  .desking-sheet .caretIconBtn {
    display: flex-end;
    border: none;
    background-color: #ffff;
    float: right;
    width: 1px;
    position: absolute;
    margin-left: 150px;
  }
  
  .desking-sheet .datePicker {
    border-left: none !important;
    border-top: none !important;
    border-right: none !important;
    border-bottom: 2px solid !important;
    border-color: #e5e5e5e5 !important;
    background-clip: padding-box;
    background-color: #fff;
    border-radius: 0px;
    outline: none;
    box-shadow: none;
  }
  
  .desking-sheet .datePicker > button {
    border: none !important;
    background-color: #fff;
  }
  
  .desking-sheet .k-input:focus,
  .k-input:active,
  .k-input:hover {
    outline: none;
    box-shadow: none;
  }
  
  /* UTIL STATUS BOX */
  .desking-sheet .status-common {
    justify-content: center;
    text-align: center;
    font-family: "Poppins Bold";
    font-weight: 500;
    font-size: 13px;
    line-height: 17px;
    letter-spacing: 0.01em;
    padding: 2px 2px;
    border-radius: 2px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.03), 0px 4px 5px rgba(0, 0, 0, 0.04);
  }
  
  .desking-sheet .first-status {
    color: var(--inchcape-white);
    background-color: var(--inchcape-purple);
  }
  
  .desking-sheet .second-status {
    color: var(--inchcape-white);
    background-color: var(--inchcape-red);
  }
  
  .desking-sheet .third-status {
    color: var(--inchcape-dark-blue);
    background-color: var(--inchcape-green);
  }
  
  .desking-sheet .fourth-status {
    color: var(--inchcape-dark-blue);
    background-color: var(--inchcape-blue);
  }
  
  .desking-sheet .fifth-status {
    color: var(--inchcape-white);
    background-color: var(--inchcape-grey);
  }
  
  .desking-sheet .sixth-status {
    color: var(--inchcape-white);
    background-color: var(--inchcape-dark-blue);
  }
  
  .desking-sheet .full-width {
    display: block;
    width: 100%;
    padding: 5px;
    border-radius: 2px;
    margin-bottom: 5px;
    margin-top: -15px;
    text-align: center;
  }
  
  .desking-sheet .break {
    height: 20px;
  }
  
  .desking-sheet .back-btn {
    cursor: pointer;
  }
  
  .desking-sheet .pdf-header {
    color: black;
    font-size: 16px;
    font-stretch: narrower;
  }

  .desking-sheet .pdf-header-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: flex-end;
    row-gap: 54px;
    column-gap: 5px;
    margin-top: 10px;
    margin-bottom: 5px;
  }
  
  .desking-sheet .pdf-header-container-footer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: flex-end;
    row-gap: 54px;
    column-gap: 5px;
    margin-top: 10px;
    margin-bottom: 0px;
  }

  .desking-sheet .pdf-header-container-wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: flex-end;
    row-gap: 1px;
  }

  .desking-sheet .pdf-header-container-label {
    display: inline-block;
    text-align: left;
    font-family: "Poppins Bold";
    font-size: 8px;
    margin: 0;
  }
  .desking-sheet .pdf-header-container-value {
    display: block;
    text-align: left;
    font-family: "Poppins";
    font-size: 8px;
    margin: 0;
  }

  .desking-sheet .pdf-header-container-label-double {
    display: inline-block;
    text-align: left;
    font-family: "Poppins Bold";
    font-size: 8px;
    margin: 0;
  }

  .desking-sheet .pdf-header-container-value-double {
    display: block;
    text-align: left;
    font-family: "Poppins";
    font-size: 8px;
    padding: 50%;
  }

    
  .desking-sheet .pdf-grid-title {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: flex-end;
    row-gap: 54px;
    column-gap: 0px;
    font-family: "Poppins Bold" !important;
    font-style: normal;
    font-weight: 500;
    text-align: left;
    letter-spacing: 0.01em;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin-bottom: -12px;
  }
  .desking-sheet .pdf-grid-title-footer {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: flex-end;
    row-gap: 0px;
    column-gap: 0px;
    font-family: "Poppins Bold" !important;
    font-style: normal;
    font-weight: 500;
    text-align: left;
    letter-spacing: 0.01em;
    flex: none;
    order: 0;
    flex-grow: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  }

  .desking-sheet .pdf-grid-title-2col {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: flex-end;
    row-gap: 54px;
    column-gap: 0px;
    font-family: "Poppins Bold" !important;
    font-style: normal;
    font-weight: 500;
    text-align: left;
    letter-spacing: 0.01em;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin-bottom: -12px;
  }

  .desking-sheet .pdf-grid-title-detail-2col {
    font-size: 8px;
    color: var(--inchcape-grey);
    font-family: "Poppins Bold";
  }
  
  
  .desking-sheet .pdf-grid-title-proposed-2col {
    font-size: 8px;
    color: var(--inchcape-grey);
    font-family: "Poppins Bold";
    font-style: normal;
    text-align: left;
  }

  .desking-sheet .pdf-grid-right-container {
    position: relative;
    top: -43.5px;
  }

  .desking-sheet .pdf-title-common {
    font-size: 8px;
    color: var(--inchcape-grey);
    font-family: "Poppins Bold";
    margin: 0;
  }

  .desking-sheet .pdf-title-2col {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: flex-end;
    row-gap: 54px;
    column-gap: 0px;
  }

  .desking-sheet .pdf-grid-title-detail {
    font-size: 8px;
    color: var(--inchcape-grey);
    font-family: "Poppins Bold";
  }

  .desking-sheet .pdf-grid-title-existing {
    font-size: 8px;
    color: var(--inchcape-grey);
    font-family: "Poppins Bold";
    margin-left: -8px;
  }
  
  .desking-sheet .pdf-grid-title-detail-footer {
    font-size: 8px;
    color: var(--inchcape-grey);
    font-family: "Poppins Bold";
    margin: 0;
  }

  .desking-sheet .pdf-grid-title-existing-footer {
    font-size: 8px;
    color: var(--inchcape-grey);
    font-family: "Poppins Bold";
    margin-left: -8px;
    margin: 0;
  }
  
  .desking-sheet .pdf-grid-title-proposed {
    font-size: 8px;
    color: #16bae7;
    font-family: "Poppins Bold";
    margin-left: 2px;
  }
  .desking-sheet .k-grid .k-header {
    display: none;
  }
  
  .desking-sheet .separator-horizontal {
    border-top: 1px solid rgba(0, 0, 0, 0.08);
  }