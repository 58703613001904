.k-list .option {
  align-items: center;
  margin-left: 5px;
}

.k-list-item.k-selected:has(.option),
.k-list-item.k-hover:has(.option),
.k-list-item:has(.option) {
  background: var(--inchcape-white) !important;
  color: var(--inchcape-dark-blue) !important;
}

.user-form .k-multiselect {
  position: relative;
}

.user-form .k-multiselect::after {
  content: "\25bc";
  display: inline-block;
  position: absolute;
  font-size: 10px;
  color: var(--inchcape-light-grey);
  z-index: 2;
  top: 15%;
  right: 2%;
  cursor: pointer;
}

.user-form .k-i-caret-alt-down {
  color: var(--inchcape-light-grey);
}

.user-form .k-multiselect .k-i-x {
  display: none;
}

.user-form .k-input-inner {
  cursor: pointer;
}

.k-list .option span {
  padding-bottom: 3px;
}

.user-form .k-multiselect .k-inner-input {
  cursor: pointer;
}

.k-list-md .k-list-item:has(.option),
.k-list-item.k-selected.k-focus:has(.option) {
  padding: 0;
}

.k-animation-container:has(.option) {
  top: 282.285px;
}

.user-form .k-i-x-circle {
  color: var(--inchcape-blue);
}

.user-form .k-input:focus,
.user-form .k-input-md {
  outline: none;
  box-shadow: none;
}

.user-form .error label,
.user-form .error p,
.user-form .error input::placeholder,
.user-form .error .k-multiselect::after,
.user-form .error .k-i-caret-alt-down {
  color: var(--inchcape-red);
}

.user-form .error p {
  font-family: "Poppins";
  font-size: 12px;
  margin-bottom: 0;
}

.user-form .error input,
.user-form .error .k-dropdownlist {
  border-bottom: 1px solid var(--inchcape-red);
}
