* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  background-color: #e5e5e5e5;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

input:focus {
  outline: none;
}

.container-fluid {
  margin: 0 !important;
  padding: 0 !important;
}

@font-face {
  font-family: "Poppins";
  src: url("../assets/fonts/Poppins-Regular.ttf");
  font-style: normal;
}

@font-face {
  font-family: "Poppins Light";
  src: url("../assets/fonts/Poppins-Light.ttf");
  font-style: normal;
}

@font-face {
  font-family: "Poppins Bold";
  src: url("../assets/fonts/Poppins-SemiBold.ttf");
  font-style: normal;
}

/* Deafult colors */
:root {
  --inchcape-blue: #16bae7;
  --inchcape-dark-blue: #00233a;
  --inchcape-purple: #9e61a4;
  --inchcape-yellow: #f2e500;
  --inchcape-red: #e40521;
  --inchcape-green: #b7cd00;
  --inchcape-grey: #39464f;
  --inchcape-light-grey: #858a8d;
  --inchcape-bg: #f5f5f5;
  --inchcape-white: #fff;
}

.main-container {
  height: 100vh;
  overflow: hidden;
}

.main-contents {
  display: grid;
  grid-template-rows: 24px auto;
  grid-template-columns: 1fr 1fr;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 1.3rem 2.5rem 2.5rem 2.5rem;
  background: var(--inchcape-bg);
  position: relative;
}
