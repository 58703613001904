.vehicles-form-container {
  width: 100%;
  grid-area: 1 / 1 / -1 / -1;
}

.vehicles-form-container .module-cont {
  height: 95%;
  padding: 20px;
  position: relative;
}

.vehicles-form-container .header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.16);
}

.vehicles-form-container .nav-bar {
  padding: 20px;
  cursor: pointer;
  height: 30px;
}

.vehicles-form-container .active-bar {
  border: 1px solid rgb(22, 186, 231);
  border-bottom: 0px;
  border-radius: 4px;
  color: #fff;
  background-color: rgb(22, 186, 231);
}

.vehicles-form-container .footer {
  padding: 1.5rem;
  position: absolute;
  bottom: 20px;
  right: 30px;
}

.vehicles-form-container button,
.vehicles-form-container label {
  cursor: pointer;
}

.vehicles-form-container .header > button,
.vehicles-form-container .footer > button {
  padding: 11px 32px;
}

.vehicles-form-container .add-row {
  padding: 5px 15px;
  font-size: 14px;
  cursor: pointer;
}

.vehicles-form-container .header label,
.vehicles-form-container .footer label {
  font-size: 14px;
  cursor: pointer;
}

.vehicles-form-container .offset-1 {
  margin-left: 6%;
}

.vehicles-form-container .k-table {
  width: 100% !important;
}

.vehicles-form-inputs,
.extra-data-form-label {
  padding-left: 20px;
  padding-top: 15px;
  width: 95%;
}

.vehicles-form-textarea {
  padding: 20px;
}

.vehicles-form-container .forms-input {
  width: 100% !important;
}


.vehicles-form-container .video-link {
  width: 30rem;
}

.vehicles-form-container .extra-fields-container {
  height: 55rem;
}

.vehicles-form-container .extra-fields-forms {
  border: 1px solid rgba(0, 0, 0, 0.16);
    padding: 10px;
    border-radius: 4px;
}

.vehicles-form-container .extra-fields-lbl {
  margin-left: 15px;
}

.vehicles-form-container .image-form-content {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  height: 64vh;
}

.vehicles-form-container .image-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  gap: 20px;
  overflow: auto;
  padding: 10px;
  max-height: 400px;
}

.vehicles-form-container .uploader-container {
  border: 3px dashed #16bae7;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  height: 300px;
  margin-top: 20px;
  width: 500px;
}

.vehicles-form-container .select-files-btn {
  padding: 11px 32px;
}

.vehicles-form-container .uploaded-image {
  width: 100%;
  height: 200px;
  position: relative;
}

.vehicles-form-container .star-icon {
  position: absolute;
  top: 170px;
  right: 30px;
  cursor: pointer;
  height: 20px;
  width: 30px;
  filter: invert(68%) sepia(99%) saturate(2833%) hue-rotate(151deg)
    brightness(99%) contrast(84%);
}

.vehicles-form-container .delete-icon-img {
  position: absolute;
  top: 170px;
  right: 0px;
  cursor: pointer;
  height: 20px;
  width: 30px;
  filter: invert(68%) sepia(99%) saturate(2833%) hue-rotate(151deg)
    brightness(99%) contrast(84%);
}

.vehicles-form-container .specs-form {
  height: 590px !important;
}

.vehicles-form-container .tech-specs-featurees-form-container {
  padding: 20px;
  height: 64vh;
}

.vehicles-form-container .tech-specs-featurees-form-container .delete-icon {
  width: 25px;
  height: 20px;
  cursor: pointer;
}

.vehicles-form-container .tech-specs-featurees-form-container .specifications {
  width: 30%;
}

.vehicles-form-container
  .tech-specs-featurees-form-container
  .specifications-values {
  width: 70%;
}

.vehicles-form-container .tech-specs-featurees-form-container .categories {
  border: 1px solid;
  border-color: rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  width: 100%;
}

.vehicles-form-container .tech-specs-featurees-form-container .category-input {
  padding: 10px;
  width: 80%;
}

.vehicles-form-container .tech-specs-featurees-form-container .category-delete {
  display: flex;
  align-items: center;
  width: 30%;
  justify-content: center;
}

.vehicles-form-container
  .tech-specs-featurees-form-container
  .features-values-label,
.vehicles-form-container
  .tech-specs-featurees-form-container
  .specs-values-label {
  margin-left: 15px;
}

.vehicles-form-container
  .tech-specs-featurees-form-container
  .features-values-value {
  margin-left: 140px;
}

.vehicles-form-container
  .tech-specs-featurees-form-container
  .specifications-values-label {
  margin-left: 15px;
}

.vehicles-form-container
  .tech-specs-featurees-form-container
  .specifications-values-value {
  margin-left: 215px;
}

.vehicles-form-container
  .tech-specs-featurees-form-container
  .specifications-values-category {
  margin-left: 220px;
}

.vehicles-form-container
  .tech-specs-featurees-form-container
  .specifications-values-inputs {
  width: 70%;
}

.vehicles-form-container
  .tech-specs-featurees-form-container
  .specifications-values-dropdown {
  width: 70%;
}

.vehicles-form-container .tech-specs-featurees-form-container .category-items {
  height: 210px;
  overflow: auto;
}

.vehicles-form-container
  .tech-specs-featurees-form-container
  .specs-values-category-items {
  height: 470px;
  overflow: auto;
}
