.user-management {
  width: 100%;
  grid-area: 1 / 1 / -1 / -1;
}

.user-management .module-cont {
  padding: 23px 33px;
  display: flex;
  flex-direction: column;
  gap: 25px;
  align-items: flex-start;
}

.user-management .header {
  padding: 1.5rem;
}

.user-management .k-grid {
  height: 71vh;
}

.user-management .grid-cont {
  overflow: hidden;
  overflow-x: auto;
}

.user-management .search img {
  width: 16px;
  height: 16px;
}

.user-management .header .search button {
  font-family: "Poppins";
  font-size: 14px;
  height: 45px;
  width: 40px;
}

.user-management .header button {
  font-family: "Poppins";
  font-size: 14px;
  height: 45px;
  width: 155px;
}

.user-management .header label {
  font-size: 14px;
  color: rgba(0, 35, 58, 0.6);
}

.user-management .offset-1 {
  margin-left: 6%;
}

.user-management .k-pager {
  padding: 1rem 0;
}
