.column-hider .k-dialog-content {
  width: min(70vw, 480px);
  max-height: 90vh;
  overflow-y: auto;
}

.column-hider .row {
  padding: 0 1rem;
}

.column-hider p {
  font-family: "Poppins Bold";
  font-size: 15px;
  color: rgba(0, 35, 58, 0.6);
}

.column-hider img {
  cursor: pointer;
}

/* @media screen and (max-width: 998.9px) {
  .column-hider .k-dialog-content {
    width: 60vw;
  }
} */
